.header {
    text-align: center;
}

.form-input {
    width: 100%;
}

.thumbnail {
    max-height: 100px;
}

.hidden {
    display: none;
}

.date-picker {
    width: 100%;
}