body {
  margin: 0;
  padding: 0;
}

.forbidden{
  min-height: 600px;
  height: 100%;
  background-color: #202020;
  
  .container {
    max-width: 600px;
  }
  
  h1 {
    margin-bottom: 100px;
    text-align: center;
    color: white !important;
  }
  
  h4 {
    text-align: center;
    padding: 10px;
  }
  
  button {
    margin-top: 100px;
  }

  .package-version {
      padding-top: 10px;
  }
}