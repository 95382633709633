.sidebar-container {
  overflow-y: auto;
  padding-bottom: 70px;
  .fab {
    right: 20px;
    bottom: 20px;
    position: absolute !important;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .listHeader {
    font-weight: bold;

    svg {
      color: #999999;
    }
  }
}
