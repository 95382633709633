html,
body,
#root {
  height: 100%;
  ::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 25px;
  }
}

.tooltip {
  font-size: 24px;
}
