.select-list-wrapper {
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px 20px;

  .list-box {
    max-height: 300px;
    overflow: auto;
  }

  .select-list {
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fafafa;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #757575;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #9e9e9e;
    }

    & li:nth-child(even) {
      background: #eeeeee;
    }

    & li:nth-child(odd) {
      background: #fafafa;
    }

    & li {
      cursor: pointer;

      &:hover {
        background: #e0e0e0;
      }
    }

  }

  .groups-caption {
    cursor: pointer;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  .group-item {
    padding: 0px;
  }

  .search-button {
    padding-top: 15px;
  }

  .clear-selected-btn {
    display: block;
    margin-top: 15px;
    margin-left: auto;
  }
}
