.language-field {
  margin: 6px 0 !important;
  & > div {
    padding: 14px 14px;
    box-sizing: border-box;
  }

  label {
    line-height: 0.6;
  }
}

.international-field-title {
  font-weight: bold;
}

.international-field-value {
  color: lightslategray;
  font-style: italic;
}

.international-field-content {
    margin-top: -7px;
}
