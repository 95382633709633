.events-wrapper {
  h2 {
    padding: 15px 0;
  }

  .fab {
    right: 20px;
    bottom: 20px;
    position: absolute !important;
  }

  .event-container {
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    width: 100%;

    .image-select-container {
      margin-bottom: 10px;
    }

    .delete-button {
      position: absolute !important;
      top: 5px;
      right: 15px;
    }
  }

  .add-event-button {
    margin: 10px 0;
  }
}
