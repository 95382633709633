.image-viewer {
  cursor: pointer;
}

.image-hint {
  font-style: italic;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.54);
}

.image-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
}

.image-select-container {
  width: 100%;

  .image-file {
    margin-bottom: 10px;

    .file-input-wrapper {
      display: flex;
    }
  }

  .image-icon {
    cursor: pointer;
  }
}

.image-error {
  font-size: 0.75em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: red;
}

.asset-uploader .au_hint{
  word-wrap: break-word;
}